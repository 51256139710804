import React, { useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import "./header.css";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import Logo from "../../assets/brand/hop-logo.png";

const Header = () => {
  const [open, setOpen] = useState(false);

  return (
    <Container maxWidth="lg" className="header">
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Box
            sx={{
              my: 3,
              "& img": {
                // maxWidth: "30%",
                width: "174px",
                maxWidth: "174px",
              },
            }}
          >
            <img src={Logo} alt="house of praise" />
          </Box>
        </Grid>
        <Grid item xs={9}>
          <>
            <div className={`show-hide ${open ? "open" : ""}`}>
              <IconButton onClick={() => setOpen(!open)}>
                {!open ? <MenuIcon /> : <CloseIcon />}
              </IconButton>
            </div>
            <ul className={`headerLeft ${open ? "show-dropdown" : ""}`}>
              <li item>
                <Link href="/">Home</Link>
              </li>
              <li item>
                <Link href="#about">About</Link>
              </li>
              <li item>
                <Link href="#location">Location</Link>
              </li>
              <li item>
                <Link href="/resources">Resources</Link>
              </li>
              <li item>
                <Link href="#give">Give</Link>
              </li>
              <li item>
                <Link href="#contact">Contact</Link>
              </li>
            </ul>
          </>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Header;
