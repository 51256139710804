import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Header from "../../components/header/Header";
import { Button } from "@mui/material";

// images
import Hero from "../../assets/home/Hero.jpeg";
import Section2 from "../../assets/home/Home-Page-Body.jpeg";
import RCCGLOGO from "../../assets/brand/rccg-white-logo-new.png";

import "./home.css";

const Home = () => {
  return (
    <Box>
      <Header />
      {/* Hero*/}
      <Box className="hero" sx={{ backgroundImage: `url('${Hero}')` }}>
        <Container>
          <Box className="hero-content">
            <h1>ACHIEVE YOUR DREAMS FULFIL YOUR DESTINY</h1>

            <a href="https://www.youtube.com/user/hopraise/live">
              <Button>WORSHIP ONLINE</Button>
            </a>
          </Box>
        </Container>
      </Box>
      {/* Section 2 */}
      <Box className="section-2">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box className="section2-image">
                <img src={Section2} alt="" />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="section2-content">
                <h2>WELCOME TO HOUSE OF PRAISE</h2>
                <h3>
                  A church that teaches the Word with simplicity & understanding, applying it to
                  every area of life in a relevant and practical way.
                </h3>
                <p>
                  At House of Praise, we are convinced that you have a destiny to walk in — a
                  destiny already concluded in the mind of God, and we believe that the greatest
                  fulfillment in life comes when you discover your destiny and walk in it.
                </p>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Section 3 */}
      <Box className="section-3">
        <Container>
          <h2>SERVICE TIME & LOCATION</h2>
          <Grid container spacing={2} className="section3-item-container">
            <Grid item xs={12} md={2}>
              <Box className="section3-item">
                <span>FRIDAY @</span>
                <h5>07:00PM</h5>
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box className="section3-item">
                <span>SUNDAY @</span>
                <h5>10:00AM</h5>
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box className="section3-item">
                <span>IGNITE @</span>
                <h5>01:00PM</h5>
              </Box>
            </Grid>
          </Grid>
          <a href="https://goo.gl/maps/fDeDruxvGp6YiL4v8">
            <h2>3105 DIXIE RD, UNIT 2, MISSISSAUGA ON L4Y 4E3</h2>
          </a>
        </Container>
      </Box>

      {/* Section 4 */}
      <Box className="section-4">
        <Container>
          <Box className="section4-content">
            <h2>RESOURCES</h2>

            <a href="/resources">
              <Button>VIEW ALL RESOURCES</Button>
            </a>
          </Box>
        </Container>
      </Box>

      {/* Section 5 */}
      <Box className="section-5">
        <Container>
          <Box className="section5-content">
            <h2>GIVE</h2>
            <p>Invest in God's Kingdom by giving today.</p>
          </Box>
          <Grid container spacing={2} className="section5-item-container">
            <Grid item xs={12} md={4}>
              <Box className="section5-item">
                <h5>07:00PM</h5>
                <p>
                  Give a single gift, or schedule recurring giving and access your giving history at
                  anytime.
                  <a href="https://tithe.ly/give?c=294940">GIVE NOW</a>
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="section5-item">
                <h5>10:00AM</h5>
                <p>
                  Text-2-Give uses industry-leading security to protect your personal information,
                  and is never charged to your phone bill. Text <strong>‘GIVE’</strong> to
                  <a href="tel:289%20769%209938">289-769-9938</a>. It is simple, seamless and
                  secure.
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="section5-item">
                <h5>01:00PM</h5>
                <p>You can also give through the HOPraise App.</p>
                <a href="https://apps.apple.com/us/app/id1062082704?ign-mpt=uo%3D4">DOWNLOAD APP</a>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Section 6 */}
      <Box className="section-6">
        <Container>
          <Box className="section6-content">
            <h2>CONTACT</h2>
            <p>Reach out with any questions you have</p>

            <Box className="section6-item">
              <a href="tel:(905)%20624-5673">
                <span>Call us directly</span>
                <strong>(905) 624-5673</strong>
              </a>
            </Box>
            <Box className="section6-item">
              <a href="tel:(905)%20624-5673">
                <span>Call us directly</span>
                <strong>(905) 624-5673</strong>
              </a>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Section 7 */}
      <Box className="section-7">
        <Container>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            className="section7-item-container"
          >
            <Grid item xs={12} md={4}>
              <Box className="section7-item">
                <p>© 2023 House of Praise. All rights reserved.</p>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="section7-item">
                <img src={RCCGLOGO} alt="RCCG" />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="section7-item">
                <p>info@houseofpraise.ca 3105 Dixie Rd, Unit 2, Mississauga ON L4Y 4E3</p>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Home;
